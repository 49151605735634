import './App.css';
import {
  FormControl,Select,MenuItem, CardContent,TextField
} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab'
import { useEffect, useState } from 'react';
import InfoBox from './InfoBox';
import Map from './Map';
import Table from './Table';
import {sortData,prettyPrintStat} from './util';
import LineGraph from './LineGraph';
import "leaflet/dist/leaflet.css";
import BarGraph from './BarGraph';
function App() {

  const [countries,setCountries] = useState(['USA','UK','India']);
  const [country,setCountry] = useState('worldwide');
  const [countryInfo,setCountryInfo] = useState({});
  const [tableData,setTableData] = useState([]);
  const [mapCenter,setMapCenter] = useState({lat:42.5,lng:1.6})
  const [mapZoom,setMapZoom] = useState(2);
  const [mapCountries,setMapCountries] = useState([]);
  const [caseType,setCaseType] = useState('cases');
  
  useEffect(()=>{
    const getCountriesData = async ()=>{
      await fetch("https://disease.sh/v3/covid-19/countries")
      .then((response)=>response.json())
      .then((data)=>{
        const countries = data.map((country)=>(
          {
            name:country.country,
            value:country.countryInfo.iso2
          }
        ));
        setTableData(sortData(data));
        setMapCountries(data);
        setCountries(countries);
      })
    }
    getCountriesData();
  },[]);
  const onCountryChange =  (event,newValue)=>{
    const countryCode = newValue && newValue.name ? newValue.name:'worldwide';
    const url = countryCode === 'worldwide' ? 'https://disease.sh/v3/covid-19/all':`https://disease.sh/v3/covid-19/countries/${countryCode}?strict=true`
    
    fetch(url)
    .then(response => response.json())
    .then(data => {
      setCountry(countryCode);
      setCountryInfo(data);
      if(countryCode != 'worldwide'){
        setMapCenter({lat:data.countryInfo.lat,lng:data.countryInfo.long});
        setMapZoom(6);
      }else{
        setMapCenter({lat:42.5,lng:1.6});
        setMapZoom(0);
      }      
    })
  }

  useEffect(()=>{
    const url = 'https://disease.sh/v3/covid-19/all';
    fetch(url)
    .then(response=>response.json())
    .then(data => setCountryInfo(data));
  },[]);
  return (
    <div className="App">
      <div className="App__left">
        <div className="app__header">
          <h1>Covid19 Tracker</h1>
          <Autocomplete
            id="combo-box-demo"
            options={countries}
            getOptionLabel={(country) => country.name}
            style={{ width: 300 }}
            onChange={onCountryChange}
            renderInput={(country) => <TextField {...country} label="Select Country" variant="outlined" />}
          />       
        </div>
        <div className="app__stats">
          <InfoBox caseType={caseType} active={caseType === 'cases'} onClick={(e)=>{setCaseType('cases')}} title="Coronavirus Cases" cases={prettyPrintStat(countryInfo.todayCases)} total={prettyPrintStat(countryInfo.cases)}/>
          <InfoBox caseType={caseType} active={caseType === 'recovered'} onClick={(e)=>{setCaseType('recovered')}} title="Recovered"  cases={prettyPrintStat(countryInfo.todayRecovered)} total={prettyPrintStat(countryInfo.recovered)} />
          <InfoBox caseType={caseType} active={caseType === 'deaths'} onClick={(e)=>{setCaseType('deaths')}} title="Deaths"  cases={prettyPrintStat(countryInfo.todayDeaths)} total={prettyPrintStat(countryInfo.deaths)}/>
        </div>
        <Map
          countries={mapCountries}  
          center={mapCenter}
          zoom={mapZoom}
          casesType={caseType}
        />
        <div className="app_country_graph">
          {country !=='worldwide' && <BarGraph casesType={caseType} country={country}/>}
        </div>        
      </div>
      <div className="App__right">
        <CardContent>
          <h3> Live Cases by Country</h3>
          <Table countries={tableData}/>
          <h3 className="app__graphTitle"> Worldwide new cases</h3>
        </CardContent>
        <LineGraph className="app__graph" casesType={caseType} />
      </div>
    </div>
  );
}

export default App;
