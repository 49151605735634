import React, { useEffect, useState } from 'react';
import {Line} from 'react-chartjs-2';
import numeral from 'numeral';

const graphOptions = {
  "cases":{
    backgroundColor:"rgba(204, 16, 52, 0.5)",
    borderColor:"#CC1034"
  },
  "recovered":{
    backgroundColor:"rgba(125, 215, 29, 0.5)",
    borderColor:"#7dd71d"
  },
  "deaths":{
    backgroundColor:"rgba(251, 68, 67, 0.5)",
    borderColor:"#fb4443"
  }
}

const options = {
  legend: {
    display: false,
  },
  elements: {
    point: {
      radius: 0,
    },
  },
  maintainAspectRatio: false,
  tooltips: {
    mode: "index",
    intersect: false,
    callbacks: {
      label: function (tooltipItem, data) {
        return numeral(tooltipItem.value).format("+0,0");
      },
    },
  },
  scales: {
    xAxes: [
      {
        type: "time",
        time: {
          format: "MM/DD/YY",
          tooltipFormat: "ll",
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          display: false,
        },
        ticks: {
          // Include a dollar sign in the ticks
          callback: function (value, index, values) {
            return numeral(value).format("0a");
          },
        },
      },
    ],
  },
};
const LineGraph = ({casesType = 'cases',...props}) => {
    const [data,setData] = useState({});
    const buildChartData = (data,casesType) => {
        const chartData = [];
        let lastDataPoint;
        
        for( let date in data[casesType]){
            if(lastDataPoint){
                const newDataPoint = {
                    x:date,
                    y:data[casesType][date] - lastDataPoint
                }
                chartData.push(newDataPoint)  ;              
            }
            lastDataPoint = data[casesType][date];
        }
        return chartData;
    }
    useEffect(()=>{
        fetch("https://disease.sh/v3/covid-19/historical/all?lastdays=365")
        .then(response => response.json())
        .then(data => {
            
            const chartData = buildChartData(data,casesType);
            console.log("Called for :",chartData);
            setData(chartData);
        })
    },[casesType]);
    
    return (        
        <div className={props.className}>
            {data?.length > 0 && (
                <Line data={
                {
                    datasets:[{
                        backgroundColor:graphOptions[casesType].backgroundColor,
                        borderColor:graphOptions[casesType].borderColor,
                        data:data
                    }]
                }
                
            }
            options={options}
            />
            )}
            
        </div>
    );
}
 
export default LineGraph;