import React from 'react';
import './InfoBox.css';
import {Card,CardContent,Typography} from '@material-ui/core';
const InfoBox = ({title,cases,active,total,caseType,...props}) => {

    return ( 
        <Card className={`infoBox ${active && `infoBox__${caseType}--selected`}`} onClick={props.onClick}>
            <CardContent>
                <Typography className="infoBox__title" color="textSecondary">{title}</Typography>
                <h2 className="infoBox__cases">{cases}</h2>
                <Typography className="infoBox__total" color="textSecondary">{total} Total</Typography>
            </CardContent>
        </Card>
     );
}
 
export default InfoBox;