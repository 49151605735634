import React, { useEffect, useState } from 'react';
import {Bar} from 'react-chartjs-2';
import numeral from 'numeral';
import './BarGraph.css';
const BarGraph = ({country,casesType}) => {
    const [data,setData] = useState({});
    const [countryName,setCountryName] = useState('');
    const graphOptions = {
        "cases":{
            backgroundColor:"rgba(204, 16, 52, 0.5)",
            borderColor:"#CC1034"
        },
        "recovered":{
            backgroundColor:"rgba(125, 215, 29, 0.5)",
            borderColor:"#7dd71d"
        },
        "deaths":{
            backgroundColor:"rgba(251, 68, 67, 0.5)",
            borderColor:"#fb4443"
        }
    }
    const buildChartData = (data,casesType) => {
        const labels = [];
        const formatedData = [];
        let lastDataPoint;
        console.log(`casesType : ${casesType}`,data)
        for( let date in data.timeline[casesType]){
            if(lastDataPoint){
                formatedData.push(data.timeline[casesType][date] - lastDataPoint);
                labels.push(date);           
            }
            lastDataPoint = data.timeline[casesType][date];
        }
        return {
                labels:labels,
                datasets:[
                    {
                        label: `${country} - ${casesType}`,
                        backgroundColor: graphOptions[casesType].backgroundColor,
                        borderColor: graphOptions[casesType].borderColor,
                        borderWidth: 2,
                        data: formatedData
                    }
                ]
            };
    }
    
    useEffect(()=>{
        fetch(`https://disease.sh/v3/covid-19/historical/${country}?lastdays=30`)
        .then(response => response.json())
        .then(data => {            
            const chartData = buildChartData(data,casesType);
            setCountryName(data.country);
            console.log("Called for :",country,chartData);
            setData(chartData);
        })
    },[country,casesType]);
    return ( 
        <div className="bargraph">
            <Bar
            data={data}
            width={100}
            height={400}
            options={{ maintainAspectRatio: false}}
            />
        </div>
     );
}
 
export default BarGraph;